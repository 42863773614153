import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { fetchChordSheets } from "../services/ChordSheetApiService";
import { Pagination, Table, Button, Form } from "react-bootstrap";
import { ChordSheet } from "../models/ChordSheet";
import { SearchType, SearchTypeWithText } from "../enums/SearchType";
import { SortDirectionType } from "../enums/SortDirectionType";
import { SortFieldType } from "../enums/SortFieldType";
import { ChordSheetSearchCriteria } from "../models/ChordSheetSearchCriteria";
import '../assets/styles/ChordSheetItems.css';
import { saveObjectToSession, loadObjectFromSession } from "../utils/sessionStorageUtils";
import { SESSION_KEY_SEARCH_INFO } from "../constants/sessionKeys";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

interface SearchInfoState {
    pageInfo: {
        pageNumber: number;
        pageSize: number;
        totalItems: number;
    };
    searchParams: { searchType: SearchType; searchText: string };
    sortConfig: { field?: SortFieldType; direction?: SortDirectionType };
}

const ChordSheetItems = () => {
    const navigate = useNavigate();

    const defaultState: SearchInfoState = {
        pageInfo: {
            pageNumber: 0,
            pageSize: 15,
            totalItems: 0,
        },
        searchParams: { searchType: SearchType.ALL, searchText: "" },
        sortConfig: {}
    };

    const [state, setState] = useState<SearchInfoState>(() => loadObjectFromSession(SESSION_KEY_SEARCH_INFO, defaultState));
    const { pageInfo, searchParams, sortConfig } = state;
    const [chordSheets, setChordSheets] = useState<ChordSheet[]>([]);
    const searchTextRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        getChordSheets().then();
    }, [pageInfo.pageNumber, pageInfo.pageSize, sortConfig]);

    useEffect(() => {
        if (searchParams.searchText === "" && searchTextRef.current) {
            searchTextRef.current.focus();
        }
    }, [searchParams.searchType]);

    const getChordSheets = async () => {
        try {
            const searchCriteria: ChordSheetSearchCriteria = {
                searchType: searchParams.searchType,
                searchText: searchParams.searchText,
                pageNumber: pageInfo.pageNumber,
                pageSize: pageInfo.pageSize,
                sortField: sortConfig?.field,
                sortDirection: sortConfig?.direction,
            };

            const pagedChordSheets = await fetchChordSheets(searchCriteria);
            setChordSheets(pagedChordSheets.content);
            setState((prevState) => {
                const newState = {
                    ...prevState,
                    pageInfo: {
                        pageNumber: pagedChordSheets.number,
                        pageSize: pagedChordSheets.size,
                        totalItems: pagedChordSheets.totalElements,
                    },
                };
                saveObjectToSession(SESSION_KEY_SEARCH_INFO, newState);
                return newState;
            });
        } catch (error) {
            console.error(`Error fetching chordSheets: ${error}`);
        }
    };

    const handlePageChange = (page: number) => {
        setState((prevState) => {
            const newState = { ...prevState, pageInfo: { ...prevState.pageInfo, pageNumber: page } };
            saveObjectToSession(SESSION_KEY_SEARCH_INFO, newState);
            return newState;
        });
    };

    const handleRowClick = (id: number) => {
        navigate(`/chord-sheets/${id}`);
    };

    const handleSearchTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setState((prevState) => ({
            ...defaultState,
            searchParams: { searchType: event.target.value as SearchType, searchText: "" }
        }));
    };

    const handleSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState((prevState) => ({ ...prevState, searchParams: { ...prevState.searchParams, searchText: event.target.value } }));
    };

    const handleSearchTextFocus = () => {
        setState((prevState) => ({ ...prevState, searchParams: { ...prevState.searchParams, searchText: "" } }));
    };

    const handleSearch = () => {
        if (pageInfo.pageNumber !== 0) {
            handlePageChange(0);
        } else {
            getChordSheets().then();
        }
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        handleSearch();
    };

    const handleSort = (field: SortFieldType) => {
        let direction: SortDirectionType.ASC | SortDirectionType.DESC = SortDirectionType.ASC;
        if (sortConfig.field === field && sortConfig.direction === SortDirectionType.ASC) {
            direction = SortDirectionType.DESC;
        }
        setState((prevState) => {
            const newState = {
                ...prevState,
                sortConfig: { field, direction }
            };
            saveObjectToSession(SESSION_KEY_SEARCH_INFO, newState);
            return newState;
        });
    };

    const renderPagination = () => {
        const totalPages = Math.ceil(pageInfo.totalItems / pageInfo.pageSize);
        const currentPage = pageInfo.pageNumber;
        const maxPagesToShow = 5;

        let startPage = Math.max(0, currentPage - Math.floor(maxPagesToShow / 2));
        let endPage = startPage + maxPagesToShow - 1;

        if (endPage >= totalPages) {
            endPage = totalPages - 1;
            startPage = Math.max(0, endPage - maxPagesToShow + 1);
        }

        const paginationItems = [];
        for (let page = startPage; page <= endPage; page++) {
            paginationItems.push(
                <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => handlePageChange(page)}
                >
                    {page + 1}
                </Pagination.Item>
            );
        }

        return (
            <Pagination>
                {currentPage > 0 && <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />}
                {paginationItems}
                {currentPage < totalPages - 1 && <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />}
            </Pagination>
        );
    };

    return (
        <div className="container chord-sheet-list">
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <Form className="mb-3" onSubmit={handleSubmit}>
                        <div className="d-flex justify-content-between">
                            <select
                                value={searchParams.searchType}
                                onChange={handleSearchTypeChange}
                                className="form-select me-2"
                                style={{width: '30%'}}
                            >
                                <option value={SearchType.ALL}>전체</option>
                                <option value={SearchType.TITLE}>제목</option>
                                <option value={SearchType.ARTIST}>가수</option>
                                <option value={SearchType.NEW}>신규</option>
                                <option value={SearchType.FAVORITE}>인기</option>
                            </select>
                            {SearchTypeWithText.includes(searchParams.searchType as SearchType) && (
                                <Form.Control
                                    type="text"
                                    value={searchParams.searchText}
                                    onChange={handleSearchTextChange}
                                    onFocus={handleSearchTextFocus}
                                    ref={searchTextRef}
                                    className="me-2"
                                    style={{ width: '35%' }}
                                />
                            )}
                            <Button variant="primary" onClick={handleSearch} style={{ width: '30%' }}>
                                <FontAwesomeIcon icon={faSearch} />
                                <small className="ms-1" style={{ fontSize: '0.7rem' }}>【{pageInfo.totalItems}】</small>
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
            {chordSheets.length === 0 && (
                <div className="row justify-content-center">
                    <div className="col-md-8 text-center">
                        조회된 항목이 없습니다.
                    </div>
                </div>
            )}
            {chordSheets.length > 0 && (
                <>
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th style={{width: '70%'}} onClick={() => handleSort(SortFieldType.TITLE)}>
                                        제목
                                        {sortConfig.field === SortFieldType.TITLE && (
                                            <FontAwesomeIcon
                                                icon={sortConfig.direction === SortDirectionType.ASC ? faSortUp : faSortDown}/>
                                        )}
                                    </th>
                                    <th style={{width: '30%'}} onClick={() => handleSort(SortFieldType.ARTIST)}>
                                        가수
                                        {sortConfig.field === SortFieldType.ARTIST && (
                                            <FontAwesomeIcon
                                                icon={sortConfig.direction === SortDirectionType.ASC ? faSortUp : faSortDown}/>
                                        )}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {chordSheets.map(chordSheet => (
                                    <tr key={chordSheet.id} onClick={() => handleRowClick(chordSheet.id)}>
                                        <td className="truncate">{chordSheet.title}</td>
                                        <td className="truncate">{chordSheet.artist}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-8 d-flex justify-content-center">
                            {renderPagination()}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ChordSheetItems;
