export enum SearchType {
    ALL = "ALL",
    TITLE = "TITLE",
    ARTIST = "ARTIST",
    NEW = "NEW",
    FAVORITE = "FAVORITE"
}

export const SearchTypeWithText: SearchType[] = [
    SearchType.ALL,
    SearchType.TITLE,
    SearchType.ARTIST
];